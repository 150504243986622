<template>
  <v-row justify="center">
    <v-dialog
      :value="true"
      persistent
      max-width="900px"
    >
      <v-card>
        <div v-if="job.jobCardId">
          <v-card-title>
            <span class="text-h6">Job number: {{ job.jobNumber }} for {{ job.clientName }}</span>
          </v-card-title>
          <v-card-text>
            <span class="text-body-2">Address: {{ job.jobAddress }}</span>
          </v-card-text>
        </div>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <seleted-job-date-picker
                  v-if="!resetting"
                  @datePicked="onDatePicked"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-autocomplete
                  v-model="vehicle"
                  :items="$store.state.vehicles"
                  item-text="reg"
                  item-value="id"
                  label="Vehicle"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Select Vehicle
                  </template>
                </v-autocomplete>
                <label>Site</label>
                <v-text-field v-model="plannedJob.site" />
                <v-autocomplete
                  v-model="plannedJob.chargehandId"
                  :items="$store.state.workers"
                  item-text="name"
                  item-value="id"
                  label="Chargehand"
                  @change="checkWorkerAvailability"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Select Chargehand
                  </template>
                </v-autocomplete>
               <v-autocomplete
                  v-model="plannedJob.worker2Id"
                  :items="$store.state.workers"
                  item-text="name"
                  item-value="id"
                  label="Worker 2"
                  @change="checkWorkerAvailability"
                >
                  <template #label>
                    Select Worker One
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="plannedJob.worker3Id"
                  :items="$store.state.workers"
                  item-text="name"
                  item-value="id"
                  label="Select Worker 3"
                  @change="checkWorkerAvailability"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="plannedJob.worker4Id"
                  :items="$store.state.workers"
                  item-text="name"
                  item-value="id"
                  label="Select Worker 4"
                  @change="checkWorkerAvailability"
                ></v-autocomplete>
                <v-checkbox
                  v-model="plannedJob.on_hold"
                  label="On Hold?"
                  color="orange darken-2"
                  @change="changeOnHold"
                ></v-checkbox>
                <v-textarea
                  solo
                  name="input-7-4"
                  v-model="plannedJob.on_hold_notes"
                  label="On Hold Notes"
                ></v-textarea>
                <v-radio-group v-model="plannedJob.day">
                  <div class="day-night-radio">
                    <v-radio
                      label="Day AM"
                      :value=2
                      color="orange darken-2"
                    ></v-radio>
                    <v-icon class="day-night-radio-icon day">
                      mdi-weather-sunny
                    </v-icon>
                  </div>
                  <div class="day-night-radio">
                    <v-radio
                      label="Day PM"
                      :value=1
                      color="orange darken-2"
                    ></v-radio>
                    <v-icon class="day-night-radio-icon day">
                      mdi-weather-sunny
                    </v-icon>
                  </div>
                  <div class="day-night-radio">
                    <v-radio
                      label="Night"
                      :value=0
                      color="orange darken-2"
                    ></v-radio>
                    <v-icon class="day-night-radio-icon night">
                      mdi-moon-waning-crescent
                    </v-icon>
                  </div>
                </v-radio-group>
                <v-checkbox
                  v-model="plannedJob.overnight"
                  label="Overnight?"
                  color="orange darken-2"
                ></v-checkbox>
                <v-textarea
                  v-if="plannedJob.overnight"
                  solo
                  v-model="plannedJob.overnight_location"
                  label="Location of overnight stay"
                ></v-textarea>
                <v-text-field
                v-if="plannedJob.overnight"
                  label="Accommodation cost"
                  v-model="plannedJob.overnight_cost"
                ></v-text-field>
                <v-text-field
                v-if="plannedJob.overnight"
                  label="Booking reference"
                  v-model="plannedJob.overnight_booking_reference"
                ></v-text-field>
                <v-btn
                  @click="saveJob"
                  small
                  color="orange darken-1"
                >
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  Save
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
              <search-jobs />
              <v-btn
                small
                color="blue-grey lighten-4"
                @click="abandonChanges"
              >
                <v-icon left>
                  mdi-close
                </v-icon>
                Abandon Changes
              </v-btn>
                <v-list>
                  <v-list-item v-for="(job, index) in jobs" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ job.jobNumber}}</v-list-item-title>
                      <v-list-item-subtitle>
                        Dept:
                        <span v-if="job.department === 1">Paint</span>
                        <span v-if="job.department === 2">Thermo</span>
                        <span v-if="job.department === 3">Paint & Thermo</span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>{{ job.clientName }}</v-list-item-subtitle>
                      <p class="text-body-2">{{ job.jobAddress }}</p>
                      <p
                        class="text-body-2"
                      >
                        {{ job.created | shortDate }}
                      </p>
                      <p>
                        {{ job.jobValue | priceInPounds | currency }}
                      </p>
                      <p>
                        <v-btn
                          @click="selectJob(index)"
                          small
                          color="orange"
                        >
                          Select
                        </v-btn>&nbsp;
                        <v-btn
                        small
                        color="orange accent-2"
                        :href="`${$store.state.domain}/job_cards/jobCard/${job.jobCardId}`"
                        target="_blank"
                        >
                        <v-icon left>
                          mdi-card-bulleted-outline
                        </v-icon>
                        Job Card
                      </v-btn>
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="saveJob"
            small
            color="orange darken-1"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Save
          </v-btn>
          <v-btn
            small
            color="blue-grey lighten-4"
            @click="abandonChanges"
          >
            <v-icon left>
              mdi-close
            </v-icon>
            Abandon Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="600"
      v-model="showWarning"
    >
      <v-card class="pt-6 pb-6">
        <v-card-text>{{ warning }}</v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            small
            @click="dismissWarning"
          >
            Dismiss warning
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SearchJobs from '@/components/Tools/SearchJobs.vue';
import { format, eachDayOfInterval } from 'date-fns';
import axios from '../../axios';
import SeletedJobDatePicker from './SelectedJob/SeletedJobDatePicker.vue';

export default {
  name: 'AddJobFromCalendar',
  props: [
    'vehicleToSave',
  ],
  components: {
    SeletedJobDatePicker,
    SearchJobs,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    listJobs() {
      return this.$store.getters.filteredConvertedJobs;
    },
    foundJobs() {
      return this.$store.state.foundJobs;
    },
  },
  data() {
    return {
      jobs: [],
      job: {},
      vehicle: null,
      plannedJob: {
        job: null,
        date: null,
        date_end: null,
        site: '',
        time: '06:00',
        time_end: '12:00',
        vehicle: null,
        chargehandId: null,
        worker2Id: null,
        worker3Id: null,
        worker4Id: null,
        on_hold: 0,
        on_hold_notes: '',
        day: 1,
      },
      showPicker: false,
      showWarning: false,
      warning: null,
      resetting: false,
    };
  },
  watch: {
    vehicle() {
      const vehicle = this.$store.state.vehicles.filter((v) => v.id === this.vehicle)[0];
      if (vehicle.chargehand) { this.plannedJob.chargehandId = vehicle.chargehand; }
      if (vehicle.worker2) { this.plannedJob.worker2Id = vehicle.worker2; }
      if (vehicle.worker3) { this.plannedJob.worker3Id = vehicle.worker3; }
      if (vehicle.worker4) { this.plannedJob.worker4Id = vehicle.worker4; }
      if (vehicle.unavailableDates) {
        const available = this.checkAvailable(vehicle);
        if (available === false) {
          this.warning = 'This vehicle has planned maintenance for the date selected. Are you sure this is OK?';
          this.showWarning = true;
        }
      }
      this.checkWorkerAvailability();
    },
    foundJobs() {
      if (this.foundJobs.length > 0) {
        this.jobs = this.foundJobs;
      } else {
        this.jobs = this.listJobs;
      }
    },
  },
  methods: {
    getInitialJobs() {
      setTimeout(() => {
        this.jobs = this.$store.getters.filteredConvertedJobs;
      }, 200);
    },
    changeOnHold() {
      if (this.plannedJob.on_hold === true) {
        this.resetting = true;
        const date = new Date();
        const futureDate = new Date(date.setMonth(date.getMonth() + 6));
        const futureShortDate = format(futureDate, 'yyyy-MM-dd');
        this.plannedJob.date = futureShortDate;
        this.plannedJob.dateEnd = futureShortDate;
        this.$store.commit('setInitialDateArray', futureShortDate);
        setTimeout(() => {
          this.resetting = false;
        }, 5);
      }
    },
    selectJob(index) {
      this.job = this.jobs[index];
      this.plannedJob.job = this.job;
    },
    abandonChanges() {
      this.$store.commit('setJobClientSearch', '');
      this.$store.commit('setJobNumberSearch', '');
      this.$store.commit('setFoundJobs', []);
      this.$emit('abandonChanges');
    },
    checkAvailable(vehicle) {
      let available = true;
      for (let i = 0; i < vehicle.unavailableDates.length; i += 1) {
        const bDs = eachDayOfInterval({
          start: new Date(vehicle.unavailableDates[i].start),
          end: new Date(vehicle.unavailableDates[i].end),
        });
        const formattedDates = [];
        for (let z = 0; z < bDs.length; z += 1) {
          formattedDates.push(`${bDs[z].getFullYear()}${(bDs[z].getMonth() + 1)}${bDs[z].getDate()}`);
        }
        const testDate = new Date(this.plannedJob.date);
        const tD = `${testDate.getFullYear()}${(testDate.getMonth() + 1)}${testDate.getDate()}`;
        available = !formattedDates.includes(tD);
        if (available === false) break;
      }
      return available;
    },
    checkWorkerAvailability() {
      let available = true;
      const workersToCheck = [];
      const unavailableWorkers = [];
      workersToCheck.push(this.plannedJob.chargehandId);
      workersToCheck.push(this.plannedJob.worker2Id);
      workersToCheck.push(this.plannedJob.worker3Id);
      workersToCheck.push(this.plannedJob.worker4Id);
      for (let i = 0; i < workersToCheck.length; i += 1) {
        const worker = this.$store.state.workers.filter(
          (wrkr) => wrkr.id === workersToCheck[i],
        )[0];
        if (worker) {
          if (worker.unavailableDates) {
            for (let x = 0; x < worker.unavailableDates.length; x += 1) {
              const bDs = eachDayOfInterval({
                start: new Date(worker.unavailableDates[x].start),
                end: new Date(worker.unavailableDates[x].end),
              });
              const formattedDates = [];
              for (let z = 0; z < bDs.length; z += 1) {
                formattedDates.push(`${bDs[z].getFullYear()}${(bDs[z].getMonth() + 1)}${bDs[z].getDate()}`);
              }
              const testDate = new Date(this.plannedJob.date);
              const tD = `${testDate.getFullYear()}${(testDate.getMonth() + 1)}${testDate.getDate()}`;
              available = !formattedDates.includes(tD);
              if (available === false) {
                unavailableWorkers.push(worker.id);
                break;
              }
            }
          }
        }
      }
      if (unavailableWorkers.length > 0) {
        this.warnAboutWorkers(unavailableWorkers);
      }
    },
    warnAboutWorkers(unavailableWorkers) {
      let message = 'The following workers are unavailable on the selected date: ';
      for (let i = 0; i < unavailableWorkers.length; i += 1) {
        const uw = this.$store.state.workers.filter((wrkr) => wrkr.id === unavailableWorkers[i])[0];
        message += `${uw.name}  `;
      }
      this.warning = message;
      this.showWarning = true;
    },
    saveJob() {
      this.plannedJob.vehicle = this.vehicle;
      this.validatePlannedJob()
        .then(() => {
          axios.post(`/plannedJobs/add.json?token=${this.token}`, this.plannedJob)
            .then((response) => {
              const newPlannedJob = this.plannedJob;
              newPlannedJob.id = response.data.id;
              this.$store.dispatch('refreshPlannedJobs');
              this.$store.dispatch('refreshConvertedJobs');
              this.$store.commit('setVehicleSearch', '');
              this.$store.commit('setJobClientSearch', '');
              this.$store.commit('setJobNumberSearch', '');
              this.$store.commit('setFoundJobs', []);
              this.$emit('newJobAdded');
            });
        })
        .catch(() => {
          this.warning = 'Please ensure you have filled in all required fields.';
          this.showWarning = true;
        });
    },
    validatePlannedJob() {
      return new Promise((resolve, reject) => {
        if (
          this.plannedJob.vehicle
          && this.plannedJob.chargehandId
          && this.plannedJob.date
          && this.plannedJob.date_end
          && this.plannedJob.time
          && this.plannedJob.time_end
        ) {
          resolve('valid');
        } else {
          reject(new Error('invalid'));
        }
      });
    },
    onDatePicked(value) {
      // eslint-disable-next-line
      this.plannedJob.date = value[0];
      if (value[1]) {
        // eslint-disable-next-line
        this.plannedJob.date_end = value[1];
      } else {
        // eslint-disable-next-line
        this.plannedJob.date_end = value[0];
      }
    },
    dismissWarning() {
      this.warning = null;
      this.showWarning = false;
    },
  },
  mounted() {
    this.jobs = this.getInitialJobs();
    // eslint-disable-next-line
    this.vehicle = this.$store.state.vehicles.filter((v) => v.reg === this.vehicleToSave)[0].id;
    // eslint-disable-next-line
    this.plannedJob.date = this.$store.state.initialDate[0];
    // eslint-disable-next-line
    this.plannedJob.date_end = this.$store.state.initialDate[0];
  },
};
</script>
